import React from "react"
import Layout from "../components/layout"
import TestimonialsCarousel from "../components/carousel/testemonials"

const CarouselPage = () => {
  return (
    <Layout>
      <div className="container">
        <TestimonialsCarousel
          title="Das sagen Kursteilnehmer"
          items={[
            {
              id: 0,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial-1-scaled.jpg",
              text:
                `Liebe Claudia! Durch die gut geführte Detox-Kur fühle ich mich deutlich energiegeladener und leistungsfähiger. Ich habe das Gefühl, dass ich dadurch meine Stoffwechsel auch wieder angekurbelt habe.
                Ein zusätzlicher positiver Nebeneffekt ist natürlich auch, dass ich dadurch 2kg weniger auf die Waage bringe. Ich versuche definitiv Aspekte aus der Kur in meinen normalen Alltag zu integrieren,
                um mich weiterhin auch besser zu fühlen und gesünder zu leben. Vielen Dank!`,
              author: " Magdalena N",
            },
            {
              id: 1,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial2-scaled.jpg",
              text:
                `Ich hatte immer wieder Kopfschmerzen und war geplagt von Heißhungerattacken und kam in der Früh schwer aus dem Bett und in die Gänge. 
                Dein Detox Programm hat mir geholfen endlich wieder zurück zu meiner Kraft zu finden. Ich stehe am Morgen endlich wieder leicht auf und habe genug Kraft für den Tag.  
                Die Heißhungerattacken waren dank der veränderten Ernährung in 3 Tagen weg! Vielen Dank für deine Hilfe!`,
              author: " Monika M",
            },
            {
              id: 2,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial-1-scaled.jpg",
              text:
                `Es ist unglaublich. So lange habe ich versucht meine Heißhungerattacken in den Griff zu bekommen. 
                Claudia hat mir ihr Programm gezeigt und nach 4 Tagen waren die Heißhungerattacken weg.`,
              author: " Vera B",
            },
            {
              id: 3,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial2-scaled.jpg",
              text:
                `Ich habe dieses Programm als Präventivmaßnahme absolviert, da ich überzeugt bin, dass Entgiften unseren Körper und Geist stärkt. 
                 Das Programm hat mich durch die Ganzheitlichkeit angesprochen. Andere Kurse reduzieren Entgiften meist auf das Körperliche. 
                 Claudia spricht mit ihrem Programm nicht nur die körperliche, sondern auch die emotionale, mentale Ebene an und das hat mir gefallen. 
                 Es hat mir so gutgetan und ich werde es einmal im Jahr wiederholen. Die persönlichen Sitzungen waren eine großartige Unterstützung und haben mir geholfen mein Leben freudvoller und leichter zu gestalten! 
                 Ich bin so froh, dass ich Claudia gefunden habe.`,
              author: " Eva - Maria H",
            },
            {
              id: 4,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial-1-scaled.jpg",
              text:
                `Mein Stoffwechsel war so träge und die Verdauung nicht normal. Ständig war ich geplagt von Blähungen und Verstopfung. Ich wusste nicht mehr weiter. In einer Persönlichen Sitzung haben wir die Nahrungsmittel herausgefunden, auf die mein Körper reagiert.
                 Zusätzlich hat mir Claudia ihr Detox Programm gezeigt. Mein Stuhlgang hat sich innerhalb von Tagen dank des Programms normalisiert. 
                 Außerdem fühle ich mich vitaler und energiegeladener. Danke Claudia!`,
              author: " Ursula K",
            },
            {
              id: 5,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial2-scaled.jpg",
              text:
                `Liebe Claudia! Deine Behandlungen machen mich freier. Dinge, die ich schon jahrelang wie schwere Koffer mit mir mitgetragen habe, erscheinen nicht mehr so wichtig.
                Ich habe andere Sichtweisen auf manche Themen und das erleichtert mein Leben ungemein. Vielen Dank für deine Hilfe!`,
              author: " Magdalena L"
            },
            {
              id: 6,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial2-scaled.jpg",
              text:
                `Innerhalb von 3 Tagen waren meine Gelenksschmerzen verschwunden. Ich habe 3 Kg abgenommen und stehe endlich wieder leichter auf. 
                Ich fühle mich fit und leicht.`,
              author: " Simone D"
            },
            {
              id: 7,
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/07/testimonial-1-scaled.jpg",
              text:
                `Super Programm! Ich habe mich sooo gut gefühlt schon nach 3 Tagen! 
                 Endlich habe ich aus meiner depressiven Verstimmung herausgefunden und habe wieder Kraft, Elan und Lebensfreude zurückgewonnen. 
                 Kann nur jedem empfehlen, das Programm zu probieren.`,
              author: " Herta L"
            }
          ]}
        />
      </div>
    </Layout>
  )
}

export default CarouselPage
